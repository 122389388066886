import React from "react";
import { Link } from "react-router-dom";
import Header from "./../components/Header";
import Footer from "./../components/Footer";


const RefundPolicy = () => {
  return (
    <>
      <Header />
      <div className="container my-5">
        <div className="row justify-content-center align-items-center">
        <h1>Refund policy</h1>
        <br/><br/><br/>
        <p>Our policy is valid for a period of 14 calendar days from the date of the purchase. If you would like to return the product for a refund during this period, you may do so provided the conditions below have been met. Please note that if the period of 14 days has lapsed, we can&#8217;t, unfortunately, offer you a refund.</p>
        <br/><br/><br/>
        <h2>Refund requirements</h2>
        <br/><br/><br/>
        <p>The following criteria must be met to qualify for a refund:</p>
        <br/><br/>
        <ul>
        <li>Product is defective</li>
        <li>Product must be in original packaging</li>
        <li>Product must be unused</li>
        <li>Product must not be damaged</li>
        </ul>
        <br/><br/><br/>
        <p>In order to ensure the above criteria has been met, all returns will be inspected. If the product does not meet the listed criteria, we reserve the right not to issue a refund.</p>
        <br/><br/><br/><br/>
        <h2>Sale and clearance items</h2>
        
        <p>Only regular priced items may be returned, unfortunately sale or clearance items cannot be returned.</p>
        <br/><br/>
        <h2>Contacting us</h2>
        <br/><br/><br/>
        <p>If you have any questions, concerns, or complaints regarding this refund policy, we encourage you to contact us using the details below:</p>
        <p><a href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;&#115;&#105;&#108;k&#97;n&#100;san&#100;&#46;&#97;ppa&#114;el&#64;g&#109;a&#105;l.&#99;&#111;&#109;">s&#105;lk&#97;&#110;ds&#97;&#110;d&#46;a&#112;p&#97;r&#101;l&#64;gm&#97;i&#108;&#46;&#99;om</a></p>
        <p>This document was last updated on February 6, 2024</p>

        <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
          {/* <button className="home-button col-md-3 col-sm-6 col-12 btn btn-success mt-5">
            <Link to="/" className="home-button text-white text-decoration-none">
              Home page
            </Link>
          </button> */}
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default RefundPolicy;