import React, {useEffect} from "react";
import Header from "./../components/Header";
import ShopSection from "./../components/homeComponents/ShopSection";
import ContactInfo from "./../components/homeComponents/ContactInfo";
import ProductCarousel from "../components/homeComponents/ProductCarousel";
import CalltoActionSection from "./../components/homeComponents/CalltoActionSection";
import Footer from "./../components/Footer";
import { checkCartStock } from "./../Redux/Actions/cartActions";
import {useParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { listProduct } from "./../Redux/Actions/ProductActions";


const HomeScreen = ({ props }) => {
  window.scrollTo(0, 0);
  // const keyword = match.params.keyword;
  // const pagenumber = match.params.pagenumber;
  // dispatch(checkCartStock());
  const {keyword, pagenumber} = useParams();
  //const { keyword, pagenumber, category} = props;
  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  useEffect(() => {
    //console.log("useEffect in ShopSection.js");
    dispatch(listProduct(keyword, pagenumber));
  }, [dispatch, keyword, pagenumber]);
  return (
    <div>
      <div className="homescreen-background-1">
        <Header />
        {/* <ShopSection keyword={keyword} pagenumber={pagenumber} /> */}
        {/* <CalltoActionSection /> */}
        <h1 className="homescreen-explore-text">EXPLORE OUR SELF LOVE COLLECTION</h1>
        <Link to="/tshirts" className="homescreen-shop-now-button">SHOP NOW</Link>
      </div>
      <div className="homescreen-custom-height-1" >
        {/* <h2 className="carousel-title">WOMEN'S COLLECTION</h2>
        <p className="carousel-subtitle">Check out our new collection.</p> */}

        <div className="custom-carousel col-md-6">
          <ProductCarousel category="belts" title="SADU SANDS STRAP COLLECTION" subtitle="Check out our strap collection"/>
        </div>
        <div className="right-carousel-image col-md-6">
          {/* <img src="https://i.imgur.com/CcXBK8E.jpg" alt="Right side" /> */}
          <img src="https://i.imgur.com/k7bfGe0.jpeg" alt="Right side" />
        </div>
      </div>
      <div className="homescreen-custom-height-2" >
        {/* <h2 className="carousel-title">WOMEN'S COLLECTION</h2>
        <p className="carousel-subtitle">Check out our new collection.</p> */}
        <div className="right-carousel-image col-md-6">
          {/* <img src="https://i.imgur.com/CcXBK8E.jpg" alt="Right side" /> */}
          <img src="https://i.imgur.com/x6fUfQE.jpeg" alt="Left side" />
        </div>
        <div className="custom-carousel col-md-6">
          <ProductCarousel category="flower_totes" title="FLOWER TOTE COLLECTION" subtitle="Check out our new tote collection"/>
        </div>
        
      </div>
      <div>
        <ContactInfo />
        <Footer />
      </div>
    </div>
  );
};

export default HomeScreen;
