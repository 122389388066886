import {
  CART_ADD_ITEM,
  CART_CLEAR_ITEMS,
  CART_REMOVE_ITEM,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
} from "../Constants/CartConstants";

export const cartReducer = (
  state = { cartItems: [], shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const newItem = action.payload;
      // console.log("cart reducer newItem:", JSON.stringify(newItem));
      const existingItemIndex = state.cartItems.findIndex(
        (x) => x.product === newItem.product && x.size === newItem.size && x.option === newItem.option
      );
      // console.log("cart reducer existingItemIndex:", existingItemIndex);

      const existItem = state.cartItems.find(
        (x) => x.key === newItem.key
      );
    
      if (existingItemIndex !== -1) {
        // If the item with the same product and size exists, update the quantity
        const updatedCartItems = state.cartItems.map((item, index) =>
          index === existingItemIndex
            ? { ...item, qty: newItem.qty, option: newItem.option, key: newItem.key }
            : { ...item } // Ensure immutability for other items
        );
    
        return {
          ...state,
          cartItems: updatedCartItems,
        };
      } else {
        // If no matching item is found, add a new item
        return {
          ...state,
          cartItems: [...state.cartItems, newItem],
        };
      }

      // const item = action.payload;
      // const existItem = state.cartItems.find((x) => x.product === item.product && x.size == item.size);

      // if (existItem) {
      //   return {
      //     ...state,
      //     cartItems: state.cartItems.map((x) =>
      //       x.product === existItem.product && x.size === existItem.size
      //       ? item
      //       : x
      //     ),
      //   };
      // } else {
      //   return {
      //     ...state,
      //     cartItems: [...state.cartItems, item],
      //   };
      // }
    case CART_REMOVE_ITEM:
      // console.log("action.payload:", action.payload);
      const { id, size, option } = action.payload;
      // console.log("cart_remove_item product:", id);
      // console.log("cart_remove_item size:", size);

      const indexToRemove = state.cartItems.findIndex((x) => {
        // console.log("x:", x);
        // console.log("Checking item: ", x.product, x.size);
        return x.product === id && x.size === size && x.option === option;
      });
      // const indexToRemove = state.cartItems.findIndex((x) => x.id === id && x.size === size);
      // console.log("indextoremove:", indexToRemove);
      if (indexToRemove !== -1) {
        const updatedCartItems = [...state.cartItems.slice(0, indexToRemove), ...state.cartItems.slice(indexToRemove + 1)];
        // console.log("updated items:", updatedCartItems);
        return {
          ...state,
          cartItems: updatedCartItems,
        };
      } else {
        return state; // No matching item found, return the current state
      }
      // return {
      //   ...state,
      //   cartItems: state.cartItems.filter((x) => x.id !== id && x.size !== size),
      // };
    case CART_SAVE_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload,
      };
    case CART_SAVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case CART_CLEAR_ITEMS:
      return {
        ...state,
        cartItems: [],
      };
    default:
      return state;
  }
};
