import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Message from "../components/LoadingError/Error";
import Loading from "../components/LoadingError/Loading";
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import { login } from "./../Redux/Actions/userActions";
import { useLocation, useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [attempts, setAttempts] = useState(0);
  const [cooldown, setCooldown] = useState(0);
  const [cooldownLevel, setCooldownLevel] = useState(0);

  const location = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const redirect = new URLSearchParams(location?.search).get('redirect') || '/';

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  useEffect(() => {
    const savedCooldown = localStorage.getItem('cooldown');
    const savedCooldownLevel = localStorage.getItem('cooldownLevel');
    const savedAttempts = localStorage.getItem('attempts');
    const cooldownTimestamp = localStorage.getItem('cooldownTimestamp');

    if (savedCooldown && cooldownTimestamp) {
      const cooldownRemaining = parseInt(savedCooldown, 10) - Math.floor((Date.now() - parseInt(cooldownTimestamp, 10)) / 1000);
      if (cooldownRemaining > 0) {
        setCooldown(cooldownRemaining);
        setCooldownLevel(parseInt(savedCooldownLevel, 10) || 0);
        setAttempts(parseInt(savedAttempts, 10) || 0);
      } else {
        localStorage.removeItem('cooldown');
        localStorage.removeItem('cooldownLevel');
        localStorage.removeItem('attempts');
        localStorage.removeItem('cooldownTimestamp');
      }
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      history(redirect);
    }
  }, [userInfo, history, redirect]);

  useEffect(() => {
    if (cooldown > 0) {
      const timer = setInterval(() => {
        setCooldown((prevCooldown) => prevCooldown - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else {
      localStorage.removeItem('cooldown');
      localStorage.removeItem('cooldownLevel');
      localStorage.removeItem('attempts');
      localStorage.removeItem('cooldownTimestamp');
    }
  }, [cooldown]);

  useEffect(() => {
    if (cooldown > 0) {
      localStorage.setItem('cooldown', cooldown);
      localStorage.setItem('cooldownLevel', cooldownLevel);
      localStorage.setItem('attempts', attempts);
      localStorage.setItem('cooldownTimestamp', Date.now());
    }
  }, [cooldown, cooldownLevel, attempts]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (cooldown > 0) return;

    dispatch(login(email, password)).then(() => {
      if (error) {
        setAttempts((prevAttempts) => {
          const newAttempts = prevAttempts + 1;
          if (newAttempts >= 5) {
            let newCooldown = 60; // Default to 60 seconds
            if (cooldownLevel === 1) {
              newCooldown = 5 * 60; // 5 minutes
            } else if (cooldownLevel === 2) {
              newCooldown = 60 * 60; // 1 hour
            }
            setCooldown(newCooldown);
            setCooldownLevel((prevLevel) => prevLevel + 1);
            return 0; // reset attempts after cooldown is set
          }
          return newAttempts;
        });
      }
    });
  };

  return (
    <>
      <Header />
      <div className="container d-flex flex-column justify-content-center align-items-center login-center">
        {error && <Message variant="alert-danger">{error}</Message>}
        {loading && <Loading />}
        {cooldown > 0 && (
          <Message variant="alert-warning">
            Too many attempts. Please wait {Math.floor(cooldown / 60)} minutes {cooldown % 60} seconds.
          </Message>
        )}
        <form
          className="Login col-md-8 col-lg-4 col-11"
          onSubmit={submitHandler}
        >
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={cooldown > 0}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={cooldown > 0}
          />
          <p>
            <Link to="/forgot-password">Forgot Password?</Link>
          </p>
          <button type="submit" disabled={cooldown > 0}>
            Login
          </button>
          <p>
            <Link to={redirect ? `/register?redirect=${redirect}` : "/register"}>
              Create Account
            </Link>
          </p>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Login;
