import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { listProduct } from "../../Redux/Actions/ProductActions";
import Carousel from 'react-multi-carousel';
import { Link } from 'react-router-dom';
import 'react-multi-carousel/lib/styles.css';
import '../../carousel.css'; // Import custom CSS

const ProductCarousel = (props) => {
  const { keyword, pagenumber, category, title, subtitle } = props;
  const dispatch = useDispatch();
  const carouselRef = useRef(null); // Create a ref for the carousel

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  useEffect(() => {
    dispatch(listProduct(keyword, pagenumber));
  }, [dispatch, keyword, pagenumber]);

  // Filter products by category
  const filteredProducts = products.filter(product => product.category === category);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 1 // Scroll one item per click
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1 // Scroll one item per click
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // Scroll one item per click
    }
  };

  const handlePrevClick = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  const handleNextClick = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  return (
    <div className="product-carousel-container">
      <h2 className="carousel-title">{title}</h2>
      <p className="carousel-subtitle">{subtitle}</p>
      <div className="carousel-wrapper">
        <button className="carousel-arrow left" onClick={handlePrevClick}>
          <i className="fas fa-chevron-left"></i>
        </button>
        <Carousel
          ref={carouselRef} // Attach ref to the carousel
          responsive={responsive}
          infinite={true}
          keyBoardControl={true}
          showDots={false}
          arrows={false} // Hide default arrows
          containerClass="carousel-container"
        >
          {filteredProducts.map((product, index) => (
            <div key={index} className="product-card">
              <Link to={`/products/${product._id}`}>
                <img
                  className="d-block w-100 product-image"
                  src={product.image}
                  alt={product.name}
                />
              </Link>
              <div className="carousel-info">
                <h3 className="carousel-name">{product.name}</h3>
                <p className="carousel-price">${product.price.toFixed(2)}</p>
                <Link to={`/products/${product._id}`} className="btn btn-light add-to-cart-btn">
                  ADD TO CART
                </Link>
              </div>
            </div>
          ))}
        </Carousel>
        <button className="carousel-arrow right" onClick={handleNextClick}>
          <i className="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
};

export default ProductCarousel;
