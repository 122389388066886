import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [activeCategories, setActiveCategories] = useState([]);

  const handleCategoryClick = (category) => {
    setActiveCategories((prevActiveCategories) =>
      prevActiveCategories.includes(category)
        ? prevActiveCategories.filter((cat) => cat !== category)
        : [...prevActiveCategories, category]
    );
  };

  const categories = [
    {
      name: 'Men',
      subcategories: [
        { name: 'Shirts', path: '/tshirts' }
      ],
    },
    {
      name: 'Women',
      subcategories: [
        { name: 'Shirts', path: '/tshirts' }
      ],
    },
    {
      name: 'Accessories',
      subcategories: [
        { name: 'Straps', path: '/belts' },
        { name: 'Totes', path: '/totes' },
      ],
    },
  ];

  return (
    <div className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="sidebar-header">
        <button className="close-btn" onClick={toggleSidebar}>
          &times;
        </button>
      </div>
      <div className="sidebar-categories">
        {categories.map((category) => (
          <div key={category.name}>
            <button
              className={`category-btn ${activeCategories.includes(category.name) ? 'active' : ''}`}
              onClick={() => handleCategoryClick(category.name)}
            >
              {category.name}
            </button>
            <div className="sidebar-subcategories">
            {activeCategories.includes(category.name) && (
              <ul className="subcategory-menu">
                {category.subcategories.map((subcategory) => (
                  <li key={subcategory.name}>
                    <Link to={subcategory.path} onClick={toggleSidebar}>
                      {subcategory.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
