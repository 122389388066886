// src/screens/ResetPassword.js
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { resetPassword } from "../Redux/Actions/userActions";
import Message from "../components/LoadingError/Error";
import Loading from "../components/LoadingError/Loading";
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import Toast from "../components/LoadingError/Toast";
import { toast } from "react-toastify";

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();

  const userResetPassword = useSelector((state) => state.userResetPassword);
  const { loading, error, success } = userResetPassword;

  const toastId = React.useRef(null);

  const Toastobjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: true,
    autoClose: 2000,
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-+=~./?])[A-Za-z\d!@#$%^&*()_\-+=~./?]{8,}$/;
    if (password !== confirmPassword) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Passwords do not match", Toastobjects);
      }
    }
    else if (password === "") {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Password cannot be empty", Toastobjects);
      }
    }
    else if (!regExp.test(password)) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Password must be 8 characters long with at least one lowercase, one uppercase, one digit, and a special character.", Toastobjects);
      }
    }
    else {
      dispatch(resetPassword(token, password));
    }
  };

  return (
    <>
      <Toast />
      <Header />
      <div className="container d-flex flex-column justify-content-center align-items-center login-center">
        {error && <Message variant="alert-danger">{error}</Message>}
        {loading && <Loading />}
        {success && <Message variant="alert-success">Password reset successful!</Message>}
        <form className="Login col-md-8 col-lg-4 col-11" onSubmit={submitHandler}>
          <input
            type="password"
            placeholder="New Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button type="submit">Reset Password</button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default ResetPassword;
