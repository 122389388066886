import React, { useEffect, useState, useRef } from "react";
import Header from "./../components/Header";
import Rating from "../components/homeComponents/Rating";
import { useParams, useNavigate } from "react-router-dom";
import Message from "./../components/LoadingError/Error";
import { useDispatch, useSelector } from "react-redux";
import ImageCarousel from '../components/homeComponents/ImageCarousel';
import {
  createProductReview,
  listProductDetails,
  updateProduct,
} from "../Redux/Actions/ProductActions";
import { Link } from "react-router-dom";
import moment from "moment";
import Loading from "../components/LoadingError/Loading";
import { PRODUCT_CREATE_REVIEW_RESET } from "../Redux/Constants/ProductConstants";
import Toast from "../components/LoadingError/Toast";
import { toast } from "react-toastify";


const SingleProduct = ({ history }) => {
  const [qty, setQty] = useState(1);
  const [quantityOptions, setQuantityOptions] = useState([]);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [userEmail, setEmail] = useState("");
  const [size, setSize] = useState("NA");
  const [option, setOption] = useState("A");
  const options = ['A', 'B'];
  const [optionAInStock, setOptionAInStock] = useState(false);
  const [optionBInStock, setOptionBInStock] = useState(false);
  const sizes = ['S/M', 'L', 'XL'];
  const [effectLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const reviewsRef = useRef(null); // Reference for scrolling to reviews

  const {productId} = useParams();
  const navigate = useNavigate();
  // console.log(`productId: ${productId}`);
  const dispatch = useDispatch();

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const productReviewCreate = useSelector((state) => state.productReviewCreate);
  const {
    loading: loadingCreateReview,
    error: errorCreateReview,
    success: successCreateReview,
  } = productReviewCreate;
  // console.log(`product image: ${product.image}`);
  // console.log("product price:", product.price);
  // console.log(`product.images: ${product.images}`);
  // console.log(`product.category: ${product.category}`);
  // console.log(`product: ${product}`);
  // console.log(`product.countInStock: ${JSON.stringify(product.countInStock)}`);
  // console.log(`product.rating: ${JSON.stringify(product.rating)}`)
  // console.log(`product.countInStock.total: ${product.countInStock.total}`);

  let formattedDescription;
  if(product.description) {
    // console.log("Original description:", JSON.stringify(product.description));
    const sanitizedDescription = product.description.replace(/\\n/g, '\n');
    formattedDescription = sanitizedDescription.replace(/\n/g, '<br />');
    // console.log("Formatted description:", formattedDescription);
    // console.log("hiii");
  }

  const reviewsPerPage = 4;
  const reversedReviews = [...product.reviews].reverse();
  const totalPages = Math.ceil(reversedReviews.length / reviewsPerPage);
  const indexOfLastReview = currentPage * reviewsPerPage;
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage;
  const currentReviews = reversedReviews.slice(indexOfFirstReview, indexOfLastReview);

  let productRating;
  if(product.rating && product.reviews.length > 0)
    productRating = product.rating.toFixed(1);
  else
    productRating = "?";
  

  const scrollToReviews = () => {
    reviewsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);


  const toastId = React.useRef(null);

  const Toastobjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: true,
    autoClose: 3000,
  };

  useEffect(() => {
    if (successCreateReview) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success("Successfully created review!", Toastobjects);
      }
      setEmail("");
      setRating(0);
      setComment("");
      dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
      const fetchData = async () => {
        try {
          setLoading(true);
          await dispatch(listProductDetails(productId));
        } catch (error) {
          // Handle error, if needed
        } finally {
          setLoading(false);
        }
      };
  
      fetchData();
      }
    }
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await dispatch(listProductDetails(productId));
      } catch (error) {
        // Handle error, if needed
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, productId]);

  useEffect(() => {
    if (product) {
      if (product.hasAandB == true) {
        // Check if option A is in stock
        if (product.countInStock.countA > 0) {
          setOption('A');
        } else {
          // If option A is not in stock, check if option B is in stock
          if (product.countInStock.countB > 0) {
            setOption('B');
          } else {
            // If neither A nor B is in stock, default to 'NA'
            setOption('NA');
          }
        }
      }
      else if(product.hasSize == true){
        setSize('S/M');
      }
      else {
        // If the product does not have both options A and B, set option to 'NA'
        setOption('NA');
      }
    }
  }, [product]);

  useEffect(() => {
    if (!product) return;

    // Check availability of options A and B
    if (product.hasAandB) {
      setOptionAInStock(product.countInStock.countA > 0);
      setOptionBInStock(product.countInStock.countB > 0);
    }
  }, [product]);

  const AddToCartHandle = (e) => {
    e.preventDefault();
    console.log("inside add to cart");
    if(product.hasSize == false && product.hasAandB == false) {
      if(qty <= product.countInStock.total) {
        setTimeout(() => {
          navigate(`/cart/${productId}?qty=${qty}&size=${size}&option=${option}`);
        }, 0);
      }
      else {
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.error("Entered quantity is not available", Toastobjects);
        }
      }
    }
    else if(product.hasSize == false && product.hasAandB == true) {
      // console.log("inside first if for cart");
      if(option === "A"){
        if(qty <= product.countInStock.countA) {
          setTimeout(() => {
            navigate(`/cart/${productId}?qty=${qty}&size=${size}&option=${option}`)
          })
        }
        else {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error("Entered quantity is not available", Toastobjects);
          }
        }  
      }
      else if(option === "B"){
        if(qty <= product.countInStock.countB) {
          setTimeout(() => {
            navigate(`/cart/${productId}?qty=${qty}&size=${size}&option=${option}`)
          })
        }
        else {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error("Entered quantity is not available", Toastobjects);
          }
        }  
      }
    }
    else {
      console.log("inside else", size);
      if(size === "S/M") {
        if(qty <= product.countInStock.smallMed) {
          setTimeout(() => {
            navigate(`/cart/${productId}?qty=${qty}&size=${size}&option=${option}`);
          }, 0);
        }
        else {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error("Entered quantity is not available", Toastobjects);
          }
        }
      }
      else if(size === "L") {
        if(qty <= product.countInStock.large) {
          setTimeout(() => {
            navigate(`/cart/${productId}?qty=${qty}&size=${size}&option=${option}`);
          }, 0);
        }
        else {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error("Entered quantity is not available", Toastobjects);
          }
        }
      }
      else if(size === "XL") {
        if(qty <= product.countInStock.xlarge) {
          setTimeout(() => {
            navigate(`/cart/${productId}?qty=${qty}&size=${size}&option=${option}`);
          }, 0);
        }
        else {
          if (!toast.isActive(toastId.current)) {
            toastId.current = toast.error("Entered quantity is not available", Toastobjects);
          }
        }
      }
    }
  };
  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createProductReview(productId, {
        rating,
        comment,
        userEmail
      })
    );
  };

  const isMobile = () => {
    return window.innerWidth <= 768;
  };

  return (
    <>
      <Toast />
      <Header />
      <div className="container single-product">
        {loading ? (
          <Loading />
        ) : error ? (
          <Message variant="alert-danger">{error}</Message>
        ) : (
          <>
            <div className="row">
              <div className="col-md-6 ">
                <div className="single-image">
                  {/* {product.hasAandB && option === 'A' ? (
                    <ImageCarousel images={product.imagesOptionA} />
                  ) : product.hasAandB && option === 'B' ? (
                    <ImageCarousel images={product.imagesOptionB} />
                  ) : product.images ? (
                    <ImageCarousel images={product.images} />
                  ) : null} */}
                  {product.images && (
                    <ImageCarousel images={product.images} />
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="product-dtl">
                  <div className="product-info">
                    <div className="product-name">
                      {product.name}
                      <div className="product-review-average">
                        <span className="ml-3">
                          <i className="fas fa-star"></i>{productRating}/5 
                          {/* <button onClick={scrollToReviews} className="btn btn-link p-0">
                            {product.reviews.length}
                          </button> reviews) */}
                        </span>
                      </div>
                    </div>
                    
                  </div>
                  {product.category && product.category === "belts" && (
                    <>
                    <p>** BAG IS NOT INCLUDED **</p>
                    <br/>
                    </>
                  )}
                  <p dangerouslySetInnerHTML={{ __html: formattedDescription }} />

                  <div className="product-count col-lg-7 ">
                    <div className="flex-box d-flex justify-content-between align-items-center">
                      <h6>Price:</h6>
                      <span>${product.price}</span>
                    </div>
                    <div className="flex-box d-flex justify-content-between align-items-center">
                      <h6>Status:</h6>
                      {product && product.countInStock && product.countInStock.total > 0 ? (
                        // Check if product has options A and B
                        (product.hasAandB ? (
                          // Check if option A is selected and countA is greater than 0
                          (option === 'A' && product.countInStock.countA > 0) ||
                          // Check if option B is selected and countB is greater than 0
                          (option === 'B' && product.countInStock.countB > 0)
                        ) : (
                          // If product does not have options A and B, check total stock
                          product.countInStock.total > 0
                        )) ? (
                          <span>In Stock</span>
                        ) : (
                          <span>Sold Out</span>
                        )
                      ) : (
                        <span>Sold Out</span>
                      )}
                    </div>
                    {/* <div className="flex-box d-flex justify-content-between align-items-center">
                      <h6>Reviews</h6>
                      <Rating
                        value={product.rating}
                        text={`${product.numReviews} reviews`}
                      />
                    </div> */}
                    {product && product.countInStock && product.hasSize && product.countInStock.total > 0 ? (
                      <>
                        <div className="flex-box d-flex justify-content-between align-items-center">
                          <h6>Size:</h6>
                          <select
                              value={size} 
                              onChange={(e) => setSize(e.target.value)}>
                              {sizes.map((size) => (
                                <option key={size} value={size}>
                                  {size}
                                </option>
                              ))}
                          </select>
                        </div>
                      </>
                    ) : null}
                    {product && product.hasAandB && product.countInStock && product.countInStock.total > 0 ? (
                      <>
                      <div className="flex-box d-flex justify-content-between align-items-center">
                        <h6>Option: </h6>
                        <div className="option-control">
                        <button
                          className={`opt-button ${option === 'A' ? 'active' : ''} ${product.countInStock.countA <= 0 ? 'disabled' : ''}`}
                          onClick={() => {
                            if (product.countInStock.countA > 0) {
                              setOption('A');
                            }
                            
                            // if (isMobile()) {
                            //   window.scrollTo({ top: 0, behavior: 'smooth' });
                            // }
                          }}
                          disabled={product.countInStock.countA <= 0}
                        >
                          <span className="symbol">A</span>
                        </button>
                        <button
                          className={`opt-button ${option === 'B' ? 'active' : ''} ${product.countInStock.countB <= 0 ? 'disabled' : ''}`}
                          onClick={() => {
                            if (product.countInStock.countB > 0) {
                              setOption('B');
                            }
                            // if (isMobile()) {
                            //   window.scrollTo({ top: 0, behavior: 'smooth' });
                            // }
                          }}
                          disabled={product.countInStock.countB <= 0}
                        >
                          <span className="symbol">B</span>
                        </button>
                        </div>
                        
                      </div>
                      </>
                    ) : null}
                    {product && product.countInStock && product.countInStock.total > 0 && (
  product.hasAandB ?
    (product.countInStock.countA > 0 || product.countInStock.countB > 0) && (
      <>
        <div className="flex-box d-flex justify-content-between align-items-center">
          <h6>Quantity:</h6>
          <div className="quantity-control">
            <button
              className="qty-button"
              onClick={() => {
                if (qty > 1) {
                  setQty(qty - 1);
                }
              }}
            >
              <span className="symbol">-</span>
            </button>
            <span>{qty}</span>
            <button
              className="qty-button"
              onClick={() => {
                setQty(qty + 1);
              }}
            >
              <span className="symbol">+</span>
            </button>
          </div>
        </div>
        <button
          onClick={AddToCartHandle}
          className="round-black-btn"
        >
          Add To Cart
        </button>
      </>
    )
    :
    product.countInStock.total > 0 && (
      <>
        <div className="flex-box d-flex justify-content-between align-items-center">
          <h6>Quantity:</h6>
          <div className="quantity-control">
            <button
              className="qty-button"
              onClick={() => {
                if (qty > 1) {
                  setQty(qty - 1);
                }
              }}
            >
              <span className="symbol">-</span>
            </button>
            <span>{qty}</span>
            <button
              className="qty-button"
              onClick={() => {
                setQty(qty + 1);
              }}
            >
              <span className="symbol">+</span>
            </button>
          </div>
        </div>
        <button
          onClick={AddToCartHandle}
          className="round-black-btn"
        >
          Add To Cart
        </button>
      </>
    )
)}

                    
                  </div>
                </div>
              </div>
            </div>

            {/* RATING */}
            <div className="row my-5">
              <div className="reviews-section col-md-6" ref={reviewsRef}>
                <h6 className="mb-3">REVIEWS</h6>
                {product.reviews.length === 0 && (
                  <Message variant={"alert-info mt-3"}>No Reviews</Message>
                )}
                {currentReviews.map((review) => (
                  <div key={review._id} className="mb-5 mb-md-3 bg-light p-3 shadow-sm rounded">
                    <strong>{review.name}</strong>
                    <Rating value={review.rating} />
                    <span>{moment(review.createdAt).calendar()}</span>
                    <div className="alert alert-info mt-3">{review.comment}</div>
                  </div>
                ))}
                {product.reviews.length > 0 && (
                  <>
                  <div className="d-flex justify-content-between">
                  <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="btn paginate-btn"
                  >
                    Previous
                  </button>
                  <span>Page {currentPage} of {totalPages}</span>
                  <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="btn paginate-btn"
                  >
                    Next
                  </button>
                </div>
                </>
                )}
              </div>
              <div className="write-review-section col-md-6">
                <h6 className="customer-review-header">WRITE A CUSTOMER REVIEW</h6>
                <div className="my-4">
                  {loadingCreateReview && <Loading />}
                  {errorCreateReview && (
                    <Message variant="alert-danger">{errorCreateReview}</Message>
                  )}
                </div>
                <form onSubmit={submitHandler}>
                  <div className="my-4">
                    <strong>Email</strong>
                    <input
                      className="col-12 bg-light p-3 mt-2 border-0 rounded"
                      type="text"
                      placeholder="Enter email associated with order"
                      value={userEmail}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="my-4">
                    <strong>Rating</strong>
                    <select
                      value={rating}
                      onChange={(e) => setRating(e.target.value)}
                      className="col-12 bg-light p-3 mt-2 border-0 rounded"
                    >
                      <option value="">Select...</option>
                      <option value="1">1 - Poor</option>
                      <option value="2">2 - Fair</option>
                      <option value="3">3 - Good</option>
                      <option value="4">4 - Very Good</option>
                      <option value="5">5 - Excellent</option>
                    </select>
                  </div>
                  <div className="my-4">
                    <strong>Comment</strong>
                    <textarea
                      row="3"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      className="col-12 bg-light p-3 mt-2 border-0 rounded"
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <button
                      disabled={loadingCreateReview}
                      className="col-12 bg-black border-0 p-3 rounded text-white"
                    >
                      SUBMIT
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SingleProduct;
