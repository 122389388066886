import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Message from "../components/LoadingError/Error";
import Loading from "../components/LoadingError/Loading";
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import { forgotPassword } from "./../Redux/Actions/userActions";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [buttonClicked, setButtonClicked] = useState(false);
  const [cooldown, setCooldown] = useState(0);

  const location = useLocation();
  const history = useNavigate();
  const dispatch = useDispatch();
  const redirect = new URLSearchParams(location?.search).get('redirect') || '/';

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  useEffect(() => {
    const cooldownEnd = localStorage.getItem("passwordResetCooldownEnd");
    if (cooldownEnd) {
      const now = new Date().getTime();
      const cooldownTime = parseInt(cooldownEnd, 10);
      if (cooldownTime > now) {
        setCooldown(cooldownTime - now);
        setButtonClicked(true);
      } else {
        localStorage.removeItem("passwordResetCooldownEnd");
      }
    }
    
  }, []);

  useEffect(() => {
    if (cooldown > 0) {
      const timer = setInterval(() => {
        setCooldown(prevCooldown => prevCooldown - 1000);
      }, 1000);

      return () => clearInterval(timer);
    } else if (cooldown <= 0 && buttonClicked) {
      setButtonClicked(false);
      localStorage.removeItem("passwordResetCooldownEnd");
    }
  }, [cooldown, buttonClicked]);

  const submitHandler = (e) => {
    e.preventDefault();
    setButtonClicked(true);
    const cooldownEnd = new Date().getTime() + 15 * 60 * 1000; // 15 minutes cooldown
    localStorage.setItem("passwordResetCooldownEnd", cooldownEnd);
    setCooldown(15 * 60 * 1000);
    dispatch(forgotPassword(email));
  };

  return (
    <>
      <Header />
      <div className="container d-flex flex-column justify-content-center align-items-center login-center">
        {error && <Message variant="alert-danger">{error}</Message>}
        {cooldown > 0 && (
          <Message variant="alert-warning">
            You may resend in {Math.floor(cooldown / 1000 / 60)} minutes {Math.floor((cooldown / 1000) % 60)} seconds. Double check your spam and junk folders!
          </Message>
        )}
        {loading && <Loading />}
        <form className="Login col-md-8 col-lg-4 col-11" onSubmit={submitHandler}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className={`reset-password-btn ${(cooldown > 0) ? "fade-out" : ""}`}
          type="submit" disabled={cooldown > 0}>
            SEND PASSWORD RESET
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
