import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { verify } from "../Redux/Actions/userActions";
import Header from "./../components/Header";

const VerifiedScreen = () => {
  const dispatch = useDispatch();
  const {userId, uniqueString} = useParams();

  //const productList = useSelector((state) => state.);
  //const { loading, error, products, page, pages } = productList;

  useEffect(() => {
    dispatch(verify(userId, uniqueString));
  }, [dispatch, userId, uniqueString]);
  return (
    <>
      <Header />
      <div className="container my-5">
        <div className="row justify-content-center align-items-center">
          <h4 className="text-center mb-2 mb-sm-5">Successfully verified!</h4>
          <img
            style={{ width: "100%", height: "300px", objectFit: "contain" }}
            src="/images/not-found.png"
            alt="Not-found"
          />
          <button className="col-md-3 col-sm-6 col-12 btn btn-success mt-5">
            <Link to="/login" className="text-white text-decoration-none">
              Login
            </Link>
          </button>
        </div>
      </div>
    </>
  );
};

export default VerifiedScreen;
