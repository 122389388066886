import React from 'react';
import { Carousel } from 'react-bootstrap';

const ImageCarousel = ({ images }) => {
  return (
    <Carousel interval={null}>
      {images.map((image, index) => (
        <Carousel.Item className="singleproduct-carousel-item" key={index}>
          <img className="img-carousel d-block w-100" src={image} alt={`Slide ${index + 1}`} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default ImageCarousel;