import React from "react";
import Header from "./../components/Header";
import ShopSection from "./../components/homeComponents/ShopSection";
import CategorizedProducts from "./../components/homeComponents/CategorizedProducts";
import ContactInfo from "./../components/homeComponents/ContactInfo";
import CalltoActionSection from "./../components/homeComponents/CalltoActionSection";
import Footer from "./../components/Footer";
import { checkCartStock } from "./../Redux/Actions/cartActions";
import {useParams} from "react-router-dom";
import { useDispatch } from "react-redux";

const TotesScreen = ({ match }) => {
  window.scrollTo(0, 0);
  const dispatch = useDispatch();
  // dispatch(checkCartStock());

  // const keyword = match.params.keyword;
  // const pagenumber = match.params.pagenumber;
  const {keyword, pagenumber} = useParams();
  return (
    <div>
      <Header />
      <CategorizedProducts keyword={keyword} pagenumber={pagenumber} categories={["totes","flower_totes"]}/>
      {/* <CalltoActionSection /> */}
      <ContactInfo />
      <Footer />
    </div>
  );
};

export default TotesScreen;
