import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer pb-4 mt-auto">
      <div className="justify-content-center d-flex">
        <div className="card-name">
          <img
            alt="mastercard"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b7/MasterCard_Logo.svg/1200px-MasterCard_Logo.svg.png"
          />
        </div>
        <div className="card-name">
          <img
            alt="visa"
            src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png"
          />
        </div>
        <div className="card-name">
          <img
            alt="express"
            src="https://icons.iconarchive.com/icons/designbolts/credit-card-payment/256/American-Express-icon.png"
          />
        </div>
        <div className="card-name">
          <img
            alt="discover"
            src="https://icons.iconarchive.com/icons/designbolts/credit-card-payment/128/Discover-icon.png"
          />
        </div>
      </div>
      <div className="justify-content-center d-flex">
        <div className="privacy-policy-link">
          <Link to="/privacypolicy">Privacy Policy</Link>
        </div>
        <div className="terms-link ms-4">
          <Link to="/terms">Terms and Conditions</Link>
        </div>
        <div className="refund-policy-link ms-4">
          <Link to="/refundpolicy">Refund Policy</Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
