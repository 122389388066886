import React, { useEffect, useState } from "react";
import Header from "./../components/Header";
import Footer from "./../components/Footer";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removefromcart, checkCartStock } from "./../Redux/Actions/cartActions";
import { updateProduct } from "./../Redux/Actions/ProductActions";

import Toast from "../components/LoadingError/Toast";
import { toast } from "react-toastify";

const CartScreen = () => {
  // window.scrollTo(0, 0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { productId } = useParams();
  const qty = new URLSearchParams(location.search).get("qty") || 1;
  const size = new URLSearchParams(location.search).get("size") || 'NA';
  const option = new URLSearchParams(location.search).get("option") || 'NA';

  dispatch(checkCartStock());

  const toastId = React.useRef(null);

  const Toastobjects = {
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: true,
    autoClose: 3000,
  };

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const total = cartItems.reduce((a, i) => a + i.qty * i.price, 0).toFixed(2);

  const [localQuantities, setLocalQuantities] = useState({});

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty, size, option));
    }
  }, [dispatch, productId, qty, size, option]);

  useEffect(() => {
    if (cartItems) {
      const newLocalQuantities = cartItems.reduce((acc, item) => {
        acc[item.product + '-' + item.size + '-' + item.option] = item.qty;
        return acc;
      }, {});
      setLocalQuantities(newLocalQuantities);
    }
  }, [cartItems]);

  const checkOutHandler = () => {
    navigate("/login?redirect=/shipping");
    // let validCheckout = true;
    // cartItems.forEach(item => {
    //   const key = item.product + '-' + item.size;
    //   let stockAvailable = 0;
    //   if (item.size === "S/M") {
    //     stockAvailable = item.countInStock.smallMed;
    //   } else if (item.size === "L") {
    //     stockAvailable = item.countInStock.large;
    //   } else if (item.size === "XL") {
    //     stockAvailable = item.countInStock.xlarge;
    //   } else if (item.size === "NA") {
    //     stockAvailable = item.countInStock.total;
    //   }

    //   if (localQuantities[key] > stockAvailable) {
    //     validCheckout = false;
    //   }
  };

  const removeFromCartHandle = (id, size, option) => {
    dispatch(removefromcart(id, size, option));
  };

  const incrementQty = (item) => {
    const key = item.product + '-' + item.size + '-' + item.option;
    setLocalQuantities((prev) => ({
      ...prev,
      [key]: parseInt(prev[key]) + 1
    }));
  };

  const decrementQty = (item) => {
    const key = item.product + '-' + item.size + '-' + item.option;
    setLocalQuantities((prev) => {
      const newQty = parseInt(prev[key]) - 1;
      return newQty >= 1 ? { ...prev, [key]: newQty } : prev;
    });
  };

  const updateCart = (item) => {
    const key = item.product + '-' + item.size + '-' + item.option;
    let validUpdate = true;
    let originalQuantities = { ...localQuantities };

    cartItems.forEach(item => {
      const currentKey = item.product + '-' + item.size + '-' + item.option;
      let stockAvailable = 0;
      if (item.size === "S/M") {
        stockAvailable = item.countInStock.smallMed;
      } else if (item.size === "L") {
        stockAvailable = item.countInStock.large;
      } else if (item.size === "XL") {
        stockAvailable = item.countInStock.xlarge;
      } else if (item.size === "NA") {
        if(item.option === "A") {
          stockAvailable = item.countInStock.countA;
        }
        else if(item.option === "B") {
          stockAvailable = item.countInStock.countB;
        }
        else {
          stockAvailable = item.countInStock.total;
        }
      }

      if (localQuantities[currentKey] > stockAvailable) {
        validUpdate = false;
        originalQuantities[currentKey] = item.qty;
      }
    });

    if (validUpdate) {
      dispatch(addToCart(item.product, localQuantities[key], item.size, item.option));
    } else {
      setLocalQuantities(originalQuantities);
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Entered quantity is not available", Toastobjects);
      }
    }
  };


  return (
    <>
      <Toast />
      <Header />
      <div className="container d-flex flex-column min-vh-100">
        {cartItems.length === 0 ? (
          <div className="alert alert-info text-center mt-3">
            <span className="text-outline">Your cart is empty</span>
            <Link
              className="btn btn-success mx-5 px-5 py-3"
              to="/"
              style={{ fontSize: "12px" }}
            >
              SHOP NOW
            </Link>
          </div>
        ) : (
          <>
            <div className="cart-total-num alert alert-info text-center mt-3">
              <span className="text-outline">Total Cart Products</span>
              <Link className="text-outline cart-total-num mx-2" to="/cart">
                ({cartItems.length})
              </Link>
            </div>
            {cartItems.map((item) => (
              <div className="cart-iterm row" key={item.product}>
                <div
                  onClick={() => removeFromCartHandle(item.product, item.size, item.option)}
                  className="remove-button d-flex justify-content-center align-items-center"
                >
                  <i className="fas fa-times"></i>
                </div>
                <div className="cart-image col-md-3">
                <img src={item.image} alt={item.name} />
                </div>
                <div className="cart-text col-md-5 d-flex align-items-center">
                  <Link to={`/products/${item.product}`}>
                  <h4>{item.name}{item.option !== 'NA' ? ` (Option: ${item.option})` : ''}</h4>
                  </Link>
                </div>
                {item.size !== 'NA' && (
                  <div className="cart-qty col-md-1 col-sm-5 mt-md-4 mt-3 mt-md-0 ml-md-2 ml-1 d-flex flex-column justify-content-center">
                    <h6>SIZE</h6>
                    <h7>{item.size}</h7>
                  </div>
                )}
                <div className="cart-qty col-md-2 col-sm-5 mt-md-5 mt-3 mt-md-0 ml-md-2 ml-1 d-flex flex-column justify-content-center">
                  <h6>QUANTITY</h6>
                  <div className="d-flex">
                    <button
                      className="btn btn-light"
                      onClick={() => decrementQty(item)}
                    >
                      -
                    </button>
                    <span className="mx-2">{localQuantities[item.product + '-' + item.size + '-' + item.option]}</span>
                    <button
                      className="btn btn-light"
                      onClick={() => incrementQty(item)}
                    >
                      +
                    </button>
                  </div>
                  <button 
                    className="cart-update-button btn btn-primary mt-2"
                    onClick={() => updateCart(item)}
                  >
                    UPDATE
                  </button>
                </div>
                <div className="cart-price mt-3 mt-md-0 col-md-2 align-items-sm-end align-items-start d-flex flex-column justify-content-center col-sm-7">
                  <h6>PRICE</h6>
                  <h4>${item.price}</h4>
                </div>
              </div>
            ))}
            <div className="total">
              <span className="sub">total:</span>
              <span className="total-price">${total}</span>
            </div>
            <hr />
            <div className="cart-buttons d-flex align-items-center row">
              <Link to="/" className="col-md-4">
                <button className="w-100">
                  <span className="text-outline">CONTINUE SHOPPING</span>
                </button>
              </Link>
              {total > 0 && (
                <>
                  <div className="col-md-4 mt-3 mt-md-0">
                    <button className="w-100" onClick={checkOutHandler}>
                      <span className="text-outline">CHECKOUT</span>
                    </button>
                  </div>
                  <div className="col-md-4 mt-3 mt-md-0">
                    <Link to="/guest-checkout">
                      <button className="btn-guest-checkout w-100">
                        <span className="text-outline">GUEST CHECKOUT</span>
                      </button>
                    </Link>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default CartScreen;
