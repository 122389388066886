import axios from "axios";
import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_SAVE_PAYMENT_METHOD,
  CART_SAVE_SHIPPING_ADDRESS,
} from "../Constants/CartConstants";
import URL from "../Url";

// CHECK CART STOCK

export const checkCartStock = () => async (dispatch, getState) => {
  // console.log("inside checkCartStock");
  try {
  const currentCart = getState().cart.cartItems;
  //console.log("currentCart:", currentCart);

  currentCart.forEach(async (item) => {
    //console.log("item._id", item.product);
    const { data } = await axios.get(`${URL}/api/products/${item.product}`);

    if(data && data.countInStock) {
      
      if(item.size == "S/M") {
        if(data.countInStock.smallMed < item.qty) {
          dispatch({
            type: CART_REMOVE_ITEM,
            payload: {
              id: item.product,
              size: item.size
            }
          });
        }
      }
      else if(item.size == "L") {
        if(data.countInStock.large < item.qty) {
          dispatch({
            type: CART_REMOVE_ITEM,
            payload: {
              id: item._id,
              size: item.size
            }
          });
        }
      }
      else if(item.size == "XL") {
        if(data.countInStock.xlarge < item.qty) {
          dispatch({
            type: CART_REMOVE_ITEM,
            payload: {
              id: item._id,
              size: item.size
            }
          });
        }
      }
      else if(item.size == "NA") {
        if(data.countInStock.total < item.qty) {
          dispatch({
            type: CART_REMOVE_ITEM,
            payload: {
              id: item._id,
              size: item.size
            }
          });
        }
      }

      }

  });

  //console.log("after checkCartStock dispatches:", JSON.stringify(getState().cart.cartItems));
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
  }
  catch (error) {
    //console.log(error);
  }
};

// ADD TO CART
export const addToCart = (id, qty, size, option) => async (dispatch, getState) => {
  const { data } = await axios.get(`${URL}/api/products/${id}`);

  // console.log("before addToCart dispatch:", JSON.stringify(getState().cart.cartItems));

  let image;
        if (data.hasAandB) {
          if (option === 'A') {
            image = data.imagesOptionA[0]; // First image of imagesOptionA
          } else if (option === 'B') {
            image = data.imagesOptionB[0]; // First image of imagesOptionB
          }
        } else {
          image = data.image;
        }

  dispatch({
    type: CART_ADD_ITEM,
    payload: {
      product: data._id,
      name: data.name,
      image: image,
      imagesOptionA: data.imagesOptionA,
      imagesOptionB: data.imagesOptionB,
      price: data.price,
      countInStock: data.countInStock,
      qty,
      size,
      option,
      hasAandB: data.hasAandB,
      key: `${data._id}-${option}-${size}`
    },
  });

  // console.log(`data.images: ${data.imagesOptionB}`);

  // console.log("after addToCart dispatch:", JSON.stringify(getState().cart.cartItems));


  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

// REMOVE PRODUCT FROM CART
export const removefromcart = (id, size, option) => (dispatch, getState) => {
  // console.log("inside remove from cart id and size:", id,size);
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: {
      id,
      size,
      option
    }
  });

  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

// SAVE SHIPPING ADDRESS
export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });

  localStorage.setItem("shippingAddress", JSON.stringify(data));
};

// SAVE PAYMENT METHOD
export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  });

  localStorage.setItem("paymentMethod", JSON.stringify(data));
};
