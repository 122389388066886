import React, { useEffect } from "react";
import "./App.css";
import "./responsive.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch, Route, Routes } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import SingleProduct from "./screens/SingleProduct";
import Login from "./screens/Login";
import Register from "./screens/Register";
import GuestCheckout from "./screens/GuestCheckout";
import CartScreen from "./screens/CartScreen";
import ShippingScreen from "./screens/ShippingScreen";
import ProfileScreen from "./screens/ProfileScreen";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import NotFound from "./screens/NotFound";
import PrivateRouter from "./PrivateRouter";
import PendingScreen from "./screens/PendingScreen";
import VerifiedScreen from "./screens/VerifiedScreen";
import SuccessfulScreen from "./screens/SuccessfulScreen";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import RefundPolicy from "./screens/RefundPolicy";
import Terms from "./screens/Terms";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import TotesScreen from "./screens/TotesScreen";
import BeltsScreen from "./screens/BeltsScreen";
import HoodiesScreen from "./screens/HoodiesScreen";
import TShirtsScreen from "./screens/TShirtsScreen";
import { useDispatch } from 'react-redux';
import { checkCartStock } from "./Redux/Actions/cartActions";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';


const App = () => {
  const dispatch = useDispatch();
  // dispatch(checkCartStock());
  // console.log("starting app");
  useEffect(() => {
    // console.log("hello");
    const intervalId = setInterval(() => {
      dispatch(checkCartStock());
    }, 15000);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch]);

  return (
    <Router>
      <div className="main-content">
        <Routes>
          <Route path="/" element={<HomeScreen/>} exact />
          <Route path="/search/:keyword" element={<HomeScreen/>} exact />
          <Route path="/page/:pagenumber" element={<HomeScreen/>} exact />
          <Route
            path="/search/:keyword/page/:pageNumber"
            element={<HomeScreen/>}
            exact
          />
          <Route path="/products/:productId" element={<SingleProduct/>} />
          <Route path="/successfulorder" element={<SuccessfulScreen/>} />
          <Route path="/pending" element={<PendingScreen/>} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/totes" element={<TotesScreen/>}/>
          <Route path="/belts" element={<BeltsScreen/>}/>
          <Route path="/hoodies" element={<HoodiesScreen/>}/>
          <Route path="/tshirts" element={<TShirtsScreen/>}/>
          <Route path="/guest-checkout" element={<GuestCheckout/>}/>
          <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
          <Route path="/refundpolicy" element={<RefundPolicy/>} />
          <Route path="/terms" element={<Terms/>} />
          <Route path="/verify/:userId/:uniqueString" element={<VerifiedScreen/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/register" element={<Register/>} />
          {/* <Route path="/pending" component={PendingScreen} /> */}
          {/* <Route exact path='/profile' element={<PrivateRouter/>}>
            <Route exact path='/profile' element={<ProfileScreen/>}/>
          </Route> */}
          <Route
            path="/profile/*"
            element={<PrivateRouter />}
          />

          <Route
          path="/profile"
          element={<ProfileScreen />}
          />
          {/* <PrivateRouter path="/profile" component={ProfileScreen} /> */}
          <Route path="/cart/:productId?" element={<CartScreen/>} />
          {/* <Route exact path='/shipping' element={<PrivateRouter/>}>
            <Route exact path='/shipping' element={<ShippingScreen/>}/>
          </Route>
          <Route exact path='/payment' element={<PrivateRouter/>}>
            <Route exact path='/payment' element={<PaymentScreen/>}/>
          </Route>
          <Route exact path='/placeorder' element={<PrivateRouter/>}>
            <Route exact path='/placeorder' element={<PlaceOrderScreen/>}/>
          </Route>
          <Route exact path='/order/:id' element={<PrivateRouter/>}>
            <Route exact path='/order/:id' element={<OrderScreen/>}/>
          </Route> */}
          <Route path="/shipping/*" element={<PrivateRouter />} />
          <Route path="/shipping" element={<ShippingScreen />} />

          <Route path="/payment/*" element={<PrivateRouter />} />
          <Route path="/payment" element={<PaymentScreen />} />

          <Route path="/placeorder/*" element={<PrivateRouter />} />
          <Route path="/placeorder" element={<PlaceOrderScreen />} />

          <Route path="/order/:id/*" element={<PrivateRouter />} />
          <Route path="/order/:id" element={<OrderScreen />} />
          {/* <PrivateRouter path="/shipping" component={ShippingScreen} />
          <PrivateRouter path="/payment" component={PaymentScreen} />
          <PrivateRouter path="/placeorder" component={PlaceOrderScreen} />
          <PrivateRouter path="/order/:id" component={OrderScreen} /> */}
          <Route path="*" element={<NotFound/>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
